import React from "react"
import Layout from "../component/Layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Lightbox from "../component/Lightbox"
import ContactCH from "../component/ContactCH"
import Quotes from "../component/Quotes"

// eslint-disable-next-line
import { Link, animateScroll as scroll } from "react-scroll"
import Availability from "../component/AvailabilityDch"
import Buttons from "../component/Buttons"
import towels from "../images/icons/001-towel.png"
import heating from "../images/icons/003-hot-thermometer.png"
import nature from "../images/icons/wood copy.png"
import hair from "../images/icons/004-hair-dryer.png"
import pets from "../images/icons/012-animal-prints.png"
import parking from "../images/icons/010-parking.png"
import wood from "../images/icons/001-log.png"
import cutlery from "../images/icons/014-cutlery.png"
import shower from "../images/icons/shower.png"
import fridge from "../images/icons/001-fridge.png"
import cook from "../images/icons/003-oven-mitt.png"
import oven from "../images/icons/002-stone-oven.png"
import toilet from "../images/icons/toilet.png"
import party from "../images/icons/002-fireworks.png"
import machine from "../images/icons/009-washing-machine.png"
import bath from "../images/icons//bath.png"
import wifi from "../images/icons//011-wifi.png"
import family from "../images/icons/013-family.png"
import dish from "../images/icons/dishwasher.png"
import iron from "../images/icons/005-iron.png"
import tele from "../images/icons/008-television.png"
import hanger from "../images/icons/006-hanger.png"
import jacu from "../images/icons/007-jacuzzi.png"
import SEO from "../component/SEO"

const dorlandsCountryHouse = ({ data }) => {
  return (
    <Layout>
      <SEO title="Dorlands Country House" />
      <section className="hero-content country-house">
        <div className="hero-title" id="heroCountryHouse">
          <div className="inner-wrap">
            {/* <h1>Country House</h1> */}
            <h1>Country House &amp; Franky's Hideout</h1>
            <h2>South Wales - Just outside Chepstow</h2>
          </div>
        </div>
        <Buttons scroll="dchouse" />
      </section>
      <section className="intro-content welcome-dch">
        <div className="slogan">
          <h2>Welcome to our Country House &amp; Franky's Hideout</h2>
          <p>
            Open the door to a warm ‘home from home’ feeling, a cosy, relaxed
            and welcoming space, with bags of style. There is an easy flow of
            moving from one area to the next, honey flagstones in the hall, lead
            to french oak floors of the dining room. Here the large windows draw
            you to the views outside, adding to the experience of light and
            space. A perfect place to bring family and friends to celebrate
            being together.
          </p>
        </div>
      </section>
      <Quotes
        quote="The house was incredible, a homely space with everything you need to cater for a large gathering. The beds were comfortable and everyone enjoyed their stay. The views from the house are second to none, and the Franky's Hideout is a real treat."
        name="Benjamin"
      />
      <section className="three-pictures-section dorlands_country_house_three_home">
        <Link
          activeClass="active"
          to="dchouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture
    pic-one"
        ></Link>
        <Link
          activeClass="active"
          to="dchouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture
    pic-two"
        ></Link>
        <Link
          activeClass="active"
          to="dchouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture
    pic-three"
        ></Link>
      </section>
      <section className="intro-content gardens-dch">
        <div className="slogan">
          <h2>GARDEN &amp; GROUNDS</h2>
          <p>
            French doors from the living space lead to the south west facing
            patio and gardens. The patio is home to the hot tub, garden
            furniture and a gas BBQ. It invites all to enjoy the alfresco
            experience!
          </p>
          <p>
            Sitting, chatting with something good to drink, while the kebabs
            sizzle on the barbie, or be totally chilled, lying in the bubbling
            hot tub as the sun sets for the day.
          </p>
          <p>
            For those who have the inclination to kick a ball about, throw the
            frisbee, or organise rounders, there is no manicured lawn here, just
            lots of space to have fun!
          </p>
        </div>
      </section>
      <section className="picture-break country-house-break"></section>
      <section className="intro-content cooking-dch">
        <div className="slogan">
          <h2>COOKING AND DINING</h2>
          <p>
            The large kitchen is a lovely space to be in, sharing news &amp;
            views as ‘foody’ preparations are made. Handcrafted oak units give a
            warmth, and ‘lived in’ feeling, as do the honey coloured flagstones,
            and granite tops. Of course the (large - sizable) AGA is perfect for
            large groups of hungry folk.
          </p>
          <p>
            The (large-expansive) windows allow for the light to stream in, and
            its great place to keep an eye on what’s happening on the patio!
          </p>
        </div>
      </section>
      <Quotes
        quote="Helen’s place was perfect for our family Christmas get together. It had everything that we needed and the hot tub and giant table were particularly great."
        name="Emma"
      />
      <section className="two-pictures-section dorlands_country_house_two_home">
        <Link
          activeClass="active"
          to="dchouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture
    pic-one"
        ></Link>
        <Link
          activeClass="active"
          to="dchouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture
    pic-two"
        ></Link>
      </section>
      <section className="intro-content living-dch">
        <div className="slogan">
          <h2>LIVING SPACES</h2>
          <p>
            A really generous room which allows space for everyone to come
            together either for eating, relaxing or both.
          </p>
          <p>
            The wooden flooring, rich rugs and silk curtains all create a
            sumptuous, comfortable ‘chic. ‘ Relax by the crackling fire as the
            sounds of chatter and cooking aromas waft in from the kitchen. There
            are books, games, dvds aplenty for you to browse.
          </p>
        </div>
      </section>
      <section className="three-pictures-section dorlands_country_house_three_home_2">
        <Link
          activeClass="active"
          to="dchouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture
    pic-one"
        ></Link>
        <Link
          activeClass="active"
          to="dchouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture
    pic-two"
        ></Link>
        <Link
          activeClass="active"
          to="dchouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture
    pic-three"
        ></Link>
      </section>
      <section className="intro-content sleeps-dch">
        <div className="slogan">
          <h2>SLEEPING</h2>
          <p>
            6 bedrooms in the house 1 bedroom in Franky's Hideout, offer
            flexible sleeping arrangements and layouts.
          </p>
          <p>
            Everything is prepared for your arrival, crisp cotton sheets,
            delicious duvets and fluffy towels adorn the beds.
          </p>
        </div>
      </section>

      <section className="picture-break country-house-break-2">
        <div className="grid sleeps-grid">
          <div className="sleeps-title">
            <h2>COUNTRY HOUSE BEDROOMS &amp; Franky's HideoutS</h2>
          </div>

          <div className="inner-grid">
            {/* <h5>First Floor</h5> */}
            <div className="bed_options">
              <div className="bed-wrap">
                <div className="bed">
                  <div className="inner-bed">
                    <h5>
                      Bedroom 1<span>Sleeps 2</span>
                    </h5>
                    <p>2 Singles</p>
                    <div className="or">
                      <span>or</span>
                    </div>
                    <p>1 Double</p>
                  </div>
                </div>
                <div className="bed">
                  <div className="inner-bed">
                    <h5>
                      Bedroom 2<span>Sleeps 2</span>
                    </h5>
                    <p>1 Double</p>
                  </div>
                </div>
                <div className="bed">
                  <div className="inner-bed">
                    <h5>
                      Bedroom 3<span>Sleeps 2</span>
                    </h5>
                    <p>1 Double</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <h5>Ground Floor</h5> */}
            <div className="bed_options">
              <div className="bed-wrap">
                <div className="bed">
                  <div className="inner-bed">
                    <h5>
                      Bedroom 4<span>Sleeps 2</span>
                    </h5>
                    <p>2 Singles</p>
                    <div className="or">
                      <span>or</span>
                    </div>
                    <p>1 Double</p>
                  </div>
                </div>

                <div className="bed">
                  <div className="inner-bed">
                    <h5>
                      Bedroom 5<span>Sleeps 2</span>
                    </h5>
                    <p>1 Double</p>
                  </div>
                </div>

                <div className="bed">
                  <div className="inner-bed">
                    <h5>
                      Bedroom 6<span>Sleeps 4</span>
                    </h5>
                    <p>4 Singles</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bed_options">
              <div className="bed-wrap">
                <div className="bed">
                  <div className="inner-bed">
                    <h5>
                      Franky's<span>Sleeps 2</span>
                    </h5>
                    <p>1 Double</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="info-content">
        <div className="amenities-content">
          <h4>Amenities</h4>
          <div className="grid">
            <div className="icons">
              <img src={towels} alt="towel and bedding icon" />
              <p className="iconstext">Towels &amp; Bedding</p>
            </div>
            <div className="icons">
              <img src={heating} alt="icon" />
              <p className="iconstext">Electric Heater</p>
            </div>
            <div className="icons">
              <img src={party} alt="party icon" />
              <p className="iconstext">Perfect for Parties</p>
            </div>
            <div className="icons">
              <img src={machine} alt="washing machine icon" />
              <p className="iconstext">Washing Machine</p>
            </div>
            <div className="icons">
              <img src={bath} alt="bath icon" />
              <p className="iconstext">Baths</p>
            </div>
            <div className="icons">
              <img src={family} alt="familt friendly icon" />
              <p className="iconstext">Family Friendly</p>
            </div>
            <div className="icons">
              <img src={wifi} alt="wifi icon" />
              <p className="iconstext">Wifi</p>
            </div>
            <div className="icons">
              <img src={dish} alt="diswasher icon" />
              <p className="iconstext">Dishwasher</p>
            </div>
            <div className="icons">
              <img src={iron} alt="iron icon" />
              <p className="iconstext">Iron</p>
            </div>
            <div className="icons">
              <img src={tele} alt="television icon" />
              <p className="iconstext">Television</p>
            </div>
            <div className="icons">
              <img src={hanger} alt="hanger icon" />
              <p className="iconstext">Hangers</p>
            </div>
            <div className="icons">
              <img src={nature} alt="icon" />
              <p className="iconstext">Enjoy Nature</p>
            </div>
            <div className="icons">
              <img src={hair} alt="icon" />
              <p className="iconstext">Hair Dryers</p>
            </div>
            <div className="icons">
              <img src={pets} alt="icon" />
              <p className="iconstext">Pet Friendly</p>
            </div>
            <div className="icons">
              <img src={parking} alt="icon" />
              <p className="iconstext">On site Parking</p>
            </div>
            <div className="icons">
              <img src={wood} alt="icon" />
              <p className="iconstext">Wood Burner</p>
            </div>
            <div className="icons">
              <img src={jacu} alt="icon hot tub" />
              <p className="iconstext">Hot Tub</p>
            </div>
            <div className="icons">
              <img src={cutlery} alt="icon" />
              <p className="iconstext">Cutlery</p>
            </div>
            <div className="icons">
              <img src={shower} alt="icon" />
              <p className="iconstext">Shower</p>
            </div>
            <div className="icons">
              <img src={fridge} alt="icon" />
              <p className="iconstext">Fridge</p>
            </div>
            <div className="icons">
              <img src={cook} alt="icon" />
              <p className="iconstext">Cooking Facilities</p>
            </div>
            <div className="icons">
              <img src={oven} alt="icon" />
              <p className="iconstext">Pizza Oven</p>
            </div>
            <div className="icons">
              <img src={toilet} alt="icon" />
              <p className="iconstext">En suite WC</p>
            </div>
          </div>
        </div>
      </section>
      <section className="intro-content hot-tub-dch">
        <div className="slogan">
          <h2>HOT TUB</h2>
          <p>
            There is nothing more delightful than relaxing in the warm bubbling
            waters, whilst enjoying a panoramic view !
          </p>
          <p>
            We do not like hidden extra costs for anything to do with booking
            our properties, we want to declare everything upfront and we like to
            provide all the essentials for you in the stated costs. However, not
            all our guests choose to use the hot tub. We have thought long and
            hard about this and have decided that each group should make the
            decision whether they will use it or not.
          </p>
          <p>
            The preparation of the tub,involves fresh filters, fresh water which
            is heated for 24 hours before each visit. It is a commitment to a
            lot of energy and resources, and only makes sense to provide it if
            it is wanted.
          </p>
        </div>
      </section>
      <section className="picture-break country-house-break-3"></section>
      <section id="avail">
        <h2>Dorlands Country House Availability</h2>
        <Availability />
      </section>

      <section className="galleries" id="dchouse">
        <h2>
          Dorlands Country House
          <br />
          &amp; Franky's Hideout Gallery
        </h2>
        <Lightbox images={data.country.edges} />
      </section>

      <section id="contact">
        <h2>
          Contact Dorlands Country House
          <br />
          &amp; Franky's Hideout
        </h2>
        <ContactCH />
      </section>
    </Layout>
  )
}

dorlandsCountryHouse.propTypes = {
  data: PropTypes.object.isRequired,
}

export default dorlandsCountryHouse

export const galleryQueryOne = graphql`
  query DorlandsCountryGallery {
    country: allFile(filter: { sourceInstanceName: { eq: "country" } }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
